<template>
  <div style="padding: 10px">
    <van-row style="margin: 20px 0px">
      <van-col span="8">
        <van-image
          round
          width="64"
          height="64"
          :src="mFaceimg"
          style="margin-left: 10px"
        />
      </van-col>
      <van-col span="16">
        <van-row style="font-size: 22px; font-weight: 600">{{ mName }}</van-row>
        <van-row style="margin-top: 10px">{{ mUserName }}</van-row>
      </van-col>
    </van-row>
    <van-cell-group :title="$t('lang.personal_info')">
      <van-cell :title="$t('lang.jobnumber')" :value="mJobNumber" />
      <van-cell :title="$t('lang.position')" :value="mPosition" />
      <van-cell :title="$t('lang.phone')" :value="mPhone" />
      <van-cell :title="$t('lang.email')" :value="mEmail" />
    </van-cell-group>
    <van-cell-group :title="$t('lang.company_info')">
      <van-cell :title="$t('lang.company')" :value="mCompany" />
      <van-cell :title="$t('lang.department')" is-link to="Department" />
      <van-cell :title="$t('lang.router')" is-link to="Line" />
    </van-cell-group>
    <van-cell-group :title="$t('lang.personal_config')">
      <van-cell :title="$t('lang.menu_changepwd')" is-link to="changepwd" />
      <van-cell :title="$t('lang.receiptnotification')">
        <template #right-icon>
          <van-switch
            size="24px"
            v-model="mIsReceiveNotice"
            @change="onChange"
          />
        </template>
      </van-cell>
    </van-cell-group>
    <div style="margin: 16px" v-show="!isapp">
      <van-button block color="#669999" @click="onClick">
        {{ $t("lang.wechatunbind") }}
      </van-button>
    </div>
    <div style="margin: 16px" v-show="isapp">
      <van-button block color="#669999" @click="onLogout">
        {{ $t("lang.logout") }}
      </van-button>
    </div>
  </div>
</template>
<style scoped>
.van-cell__value {
  min-width: 70%;
}
</style>
<script>
import { SetRecevieNoticeState, RemoveOpenID } from "@/api/index.js";
import { Dialog } from "vant";

export default {
  data() {
    return {
      mFaceimg: "",
      mName: "",
      mUserName: "",
      mJobNumber: "",
      mPosition: "",
      mPhone: "",
      mEmail: "",
      mCompany: "",
      mIsReceiveNotice: false,
      isapp: false,
    };
  },

  mounted() {
    let client = localStorage.getItem("client") || "wechat";
    this.isapp = client == "app";

    let objUser = this.$store.getters.getUser;
    this.mFaceimg = this.$store.getters.getFace;
    this.mName = objUser.name;
    this.mUserName = objUser.userName;
    this.mJobNumber = objUser.jobNumber;
    this.mPosition = objUser.position;
    this.mPhone = objUser.phone;
    this.mEmail = objUser.email;
    this.mIsReceiveNotice = objUser.isReceiveNotice;

    let companys = this.$store.getters.getCompanys;
    this.mCompany = companys.length > 0 ? companys[0].name : "";
  },

  methods: {
    onChange(v) {
      SetRecevieNoticeState(
        {
          token: this.$store.getters.getToken,
          openId: this.$store.getters.getOpenID,
          isReceiveNotice: v,
        },
        (ret) => {
          if (ret && ret.code == 0) {
            this.$Toast(
              v
                ? this.$t("lang.receiptnotification_open")
                : this.$t("lang.receiptnotification_close")
            );
          } else {
            this.$Toast(!ret ? this.$t("lang.operate_fail") : ret.message);
          }
        }
      );
    },

    onClick() {
      Dialog.confirm({
        message: this.$t("lang.wechatunbind_confirm"),
      })
        .then(() => {
          RemoveOpenID(
            {
              token: this.$store.getters.getToken,
              openId: this.$store.getters.getOpenID,
              userName: this.$store.getters.getUser.userName,
            },
            (ret) => {
              if (ret && ret.code == 0) {
                Dialog.alert({
                  message: this.$t("lang.wechatunbind_success"),
                }).then(() => {
                  window.location.href = this.$Config.ClientUrl();
                });
              } else {
                this.$Toast(!ret ? this.$t("lang.operate_fail") : ret.message);
              }
            }
          );
        })
        .catch(() => {
          // on cancel
        });
    },

    onLogout() {
      window.signOut.postMessage("");
    },
  },
};
</script>
